import { types, flow } from 'mobx-state-tree';
import API from '../../api/stats';
import requsetErrorMessage from '../../helpers/requsetErrorMessage';
import TeamStats from './TeamStats';
import PlayerStats from './PlayerStats';
import GoalieStats from './GoalieStats';
import Team from '../teamsStore/Team';
import APITeams from '../../api/teams';
import APIMatches from '../../api/matches';

const StatsStore = types
    .model('StatsStore', {
        teamStats: types.optional(types.array(TeamStats), []),
        playerStats: types.optional(types.array(PlayerStats), []),
        goalieStats: types.optional(types.array(GoalieStats), []),
        totalPagesPlayers: types.optional(types.number, 0),
        totalPagesGoalies: types.optional(types.number, 0),
        teams: types.array(Team),
        phases: types.array(types.frozen({ id: types.identifierNumber, acronym: types.string })),
        selectedPagePlayers: types.optional(types.number, 1),
        selectedPageGoalies: types.optional(types.number, 1),
        error: types.union(
            types.optional(types.string, ''),
            types.optional(types.array(types.string), []),
        ),
        dataLoaded: types.optional(types.boolean, false),
    })
    .actions((self) => ({
        getPlayerStats: flow(function* getPlayerStats(queries) {
            self.error = '';
            self.dataLoaded = false;
            try {
                const res = yield API.getPlayerStatsPerSeason({
                    params: { ...queries, limit: 50 },
                });
                self.playerStats = res.data.items;
                self.totalPagesPlayers = res.data.total_pages_count;
                self.dataLoaded = true;
            } catch (error) {
                self.playerStats = [];
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
                self.dataLoaded = true;
            }
        }),
        getGoalieStats: flow(function* getGoalieStats(queries) {
            self.error = '';
            self.dataLoaded = false;
            try {
                const res = yield API.getGoalieStatsPerSeason({
                    params: {
                        ...queries,
                        limit: 500,
                    },
                });
                self.goalieStats = res.data.items;
                self.totalPagesGoalies = res.data.total_pages_count;
                self.dataLoaded = true;
            } catch (error) {
                self.goalieStats = [];
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
                self.dataLoaded = true;
            }
        }),
        getTeamStats: flow(function* getTeamStats(league_id, queries) {
            self.error = '';
            self.dataLoaded = false;
            try {
                const res = yield API.getTeamStatsPerSeason({
                    params: {
                        league_id,
                        ...queries,
                    },
                });
                self.teamStats = res.data;
                self.dataLoaded = true;
            } catch (error) {
                self.dataLoaded = true;
                self.teamStats = [];
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        getTeams: flow(function* getTeams(league_id) {
            self.error = '';
            self.dataLoaded = false;
            try {
                const res = yield APITeams.getTeams({
                    params: {
                        league_id,
                    },
                });
                self.teams = res.data.items;
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        getPhases: flow(function* getPhases(league_id) {
            self.error = '';
            self.dataLoaded = false;
            try {
                const res = yield APIMatches.getPhases({
                    params: {
                        league_id,
                    },
                });
                self.phases = res.data.items;
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        setSelectedPagePlayers(page) {
            self.selectedPagePlayers = page;
        },
        setSelectedPageGoalies(page) {
            self.selectedPageGoalies = page;
        },
        setSearchQuery(query) {
            self.searchQuery = query;
        },
        clearTeamStats() {
            self.teamStats = [];
            self.dataLoaded = false;
        },
        clearStats() {
            self.playerStats = [];
            self.goalieStats = [];
            self.dataLoaded = false;
            self.selectedPagePlayers = 1;
            self.selectedPageGoalies = 1;
        },
        setDataLoaded() {
            self.dataLoaded = true;
        },
    }));

export default StatsStore;
