import { types } from 'mobx-state-tree';

const GoalieStats = types.model('PlayerStats', {
    player: types.frozen({ first_name: types.string, last_name: types.string }),
    team: types.frozen({ image_url: types.string, name: types.string, acronym: types.string }),
    season: types.optional(types.number, 0),
    games_played: types.optional(types.number, 0),
    games_started: types.optional(types.number, 0),
    games_won: types.optional(types.number, 0),
    games_lost: types.optional(types.number, 0),
    ties: types.optional(types.number, 0),
    overtime_losses: types.optional(types.number, 0),
    shots_again: types.optional(types.number, 0),
    saves: types.optional(types.string, ''),
    goals_against_goalkeeper: types.optional(types.number, 0),
    saved: types.optional(types.number, 0),
    goals_against_average: types.optional(types.number, 0),
    time_on_ice_total: types.optional(types.number, 0),
    shotouts: types.optional(types.number, 0),
    goals: types.optional(types.number, 0),
    assists: types.optional(types.number, 0),
    points: types.optional(types.number, 0),
    penalty_in_minutes: types.optional(types.number, 0),
});

export default GoalieStats;
