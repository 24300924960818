import React, { useEffect, useState } from 'react';
import translations from '../../translations/de';

import { useParams } from 'react-router-dom';
import { useRef } from 'react';
import StatsFilter from '../../components/StatsFilters';
import Select from '../../components/Select';
import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import ItemImage from '../../components/ItemImage';

import debounce from 'lodash.debounce';
import APITeam from '../../api/teams';
import { observer } from 'mobx-react-lite';

import { useStatsFilterContext } from '../../context/statsFilters';

const GoaliesStatsTable = ({ store }) => {
    const { league_id } = useParams();
    const [queryParams, setQueryParams] = useState(undefined);
    const teamRef = useRef();
    const phaseRef = useRef();
    const [searchName, setSearchName] = useState(null);
    const [playerOptions, setPlayerOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sortBy, setSortBy] = useState(null);
    const { filters, handleFilterChange } = useStatsFilterContext();

    const handleSearchName = debounce((e) => {
        setSearchName(e);
    }, 500);

    useEffect(() => {
        league_id &&
            queryParams &&
            store.getGoalieStats({
                league_id: league_id,
                sort: sortBy,
                page: store.selectedPageGoalies,
                ...queryParams,
            });
    }, [league_id, sortBy, store, store.selectedPageGoalies, queryParams]);

    useEffect(() => {
        if (searchName !== '' && searchName !== null) {
            setLoading(true);
            APITeam.getPlayers({ params: { league_id: league_id, first_name: searchName } }).then(
                (res) => {
                    setPlayerOptions(res.data.items);
                    setLoading(false);
                },
            );
        }
    }, [searchName, league_id]);

    var teamOptions = [{ team_external_id: null, name: translations.all }, ...store?.teams];

    return (
        <>
            <StatsFilter
                showSeasonFilter={true}
                showNationalityFilter={true}
                activeSection={`${league_id}-goalie_stats`}
                returnQueries={(e) => setQueryParams((prevParams) => ({ ...prevParams, ...e }))}
                filters={filters}
                handleFilterChange={handleFilterChange}
            >
                <div className="filter-box">
                    <Select
                        ref={teamRef}
                        label="Choose Team"
                        options={teamOptions.map((team) => ({
                            value: team.external_id,
                            label: team.name,
                        }))}
                        defaultValue={{ label: translations.all, value: null }}
                        onChangeCallback={(e) =>
                            setQueryParams((prevParams) => ({
                                ...prevParams,
                                team_external_id: e?.value ?? null,
                            }))
                        }
                    />
                </div>
                <div className="filter-box">
                    <Select
                        ref={phaseRef}
                        label="Choose Phase"
                        options={store?.phases.map((phase) => ({
                            value: phase.id,
                            label: phase.acronym,
                        }))}
                        isClearable={true}
                        onChangeCallback={(e) =>
                            setQueryParams((prevParams) => ({
                                ...prevParams,
                                phase_id: e?.value ?? null,
                            }))
                        }
                    />
                </div>
                <div className="filter-box">
                    <Select
                        ref={teamRef}
                        label={translations.player_name}
                        options={playerOptions?.map((player) => ({
                            value: player.id,
                            label: `${player.first_name} ${player.last_name}`,
                        }))}
                        isClearable={true}
                        isLoading={loading}
                        onInputChange={(e) => handleSearchName(e)}
                        onChangeCallback={(e) =>
                            setQueryParams((prevParams) => ({
                                ...prevParams,
                                player_id: e?.value ?? null,
                            }))
                        }
                    />
                </div>
            </StatsFilter>
            <Table
                tableName={translations.goalie}
                sortTable={(e) => setSortBy(e)}
                isLoaded={store.dataLoaded}
                tablePagination={
                    store.totalPagesGoalies > 1 && (
                        <Pagination
                            totalPages={store.totalPagesGoalies}
                            selectedPage={store.selectedPageGoalies}
                            onSelectedPageChange={(selected) =>
                                store.setSelectedPageGoalies(selected)
                            }
                        />
                    )
                }
                columns={[
                    {
                        Header: 'NO',
                        accessor: 'rank',
                        Cell: ({ value }) => <div>{value}</div>,
                    },
                    {
                        Header: 'Player',
                        accessor: 'player',
                        isFixedWider: true,
                        Cell: ({ value }) => (
                            <div>
                                {value?.first_name} {value?.last_name}
                            </div>
                        ),
                    },
                    {
                        Header: 'S',
                        accessor: 'season',
                        title: 'Season',
                        Cell: ({ value }) => <div>{value}</div>,
                    },
                    {
                        Header: 'Team',
                        accessor: 'team',
                        Cell: ({ value }) => (
                            <div>
                                <ItemImage
                                    imgSrc={value}
                                    alt="team"
                                    listView={true}
                                    smaller={true}
                                />
                            </div>
                        ),
                    },
                    {
                        Header: 'GP',
                        accessor: 'games_played',
                        title: 'Games Played',
                        Cell: ({ value }) => <div>{value}</div>,
                    },
                    {
                        Header: 'GS',
                        accessor: 'games_started',
                        title: 'Games Started',
                        Cell: ({ value }) => <div>{value}</div>,
                    },
                    {
                        Header: 'W',
                        accessor: 'games_won',
                        title: 'Wins',
                        Cell: ({ value }) => <div>{value}</div>,
                    },
                    {
                        Header: 'L',
                        accessor: 'games_lost',
                        title: 'Losses',
                        Cell: ({ value }) => <div>{value}</div>,
                    },
                    {
                        Header: 'T',
                        accessor: 'ties',
                        title: 'Ties',
                        Cell: ({ value }) => <div>{value}</div>,
                    },
                    {
                        Header: 'OT',
                        accessor: 'overtime_losses',
                        title: 'Overtime Losses',
                        Cell: ({ value }) => <div>{value}</div>,
                    },

                    {
                        Header: 'GA',
                        accessor: 'goals_against_goalkeeper',
                        title: 'Goals Against',
                        Cell: ({ value }) => <div>{value}</div>,
                    },
                    {
                        Header: 'Sv%',
                        accessor: 'saves',
                        title: 'Save Percentage',
                        Cell: ({ value }) => <div>{value}%</div>,
                    },
                    {
                        Header: 'GAA',
                        accessor: 'goals_against_average',
                        title: 'Goals against average',
                        Cell: ({ value }) => <div>{value}</div>,
                    },
                    {
                        Header: 'TOI',
                        accessor: 'time_on_ice_total',
                        title: 'Time On Ice',
                        Cell: ({ value }) => <div>{value}</div>,
                    },
                    {
                        Header: 'SO',
                        accessor: 'shotouts',
                        title: 'Shotouts',
                        Cell: ({ value }) => <div>{value}</div>,
                    },
                    {
                        Header: 'G',
                        accessor: 'goals',
                        title: 'Goals',
                        Cell: ({ value }) => <div>{value}</div>,
                    },
                    {
                        Header: 'A',
                        accessor: 'assists',
                        title: 'Assists',
                        Cell: ({ value }) => <div>{value}</div>,
                    },
                    {
                        Header: 'P',
                        accessor: 'points',
                        title: 'Points',
                        Cell: ({ value }) => <div>{value}</div>,
                    },
                    {
                        Header: 'PIM',
                        accessor: 'penalty_in_minutes',
                        title: 'Penalty Minutes',
                        Cell: ({ value }) => <div>{value}</div>,
                    },
                ]}
                data={store.goalieStats.map(
                    (
                        {
                            player,
                            season,
                            team,
                            games_played,
                            games_started,
                            games_won,
                            games_lost,
                            ties,
                            overtime_losses,
                            shots_again,
                            saves,
                            goals_against_goalkeeper,
                            goals_against_average,
                            time_on_ice_total,
                            shotouts,
                            goals,
                            assists,
                            points,
                            penalty_in_minutes,
                        },
                        index,
                    ) => {
                        return {
                            rank: (store.selectedPageGoalies - 1) * 50 + index + 1,
                            player,
                            season,
                            team: team.image_url,
                            games_played,
                            games_started,
                            games_won,
                            games_lost,
                            ties,
                            overtime_losses,
                            shots_again,
                            saves,
                            goals_against_goalkeeper,
                            goals_against_average: goals_against_average.toFixed(2),
                            time_on_ice_total,
                            shotouts,
                            goals,
                            assists,
                            points,
                            penalty_in_minutes,
                        };
                    },
                )}
            />
        </>
    );
};

export default observer(GoaliesStatsTable);
