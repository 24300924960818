import { types } from 'mobx-state-tree';

const PlayerStats = types.model('PlayerStats', {
    player: types.frozen({ first_name: types.string, last_name: types.string }),
    team: types.frozen({ image_url: types.string, name: types.string, acronym: types.string }),
    games_played: types.maybeNull(types.number),
    assists: types.maybeNull(types.optional(types.number, 0)),
    goals: types.maybeNull(types.number),
    points: types.maybeNull(types.optional(types.number, 0)),
    penalty_in_minutes: types.maybeNull(types.number),
    plus: types.maybeNull(types.number),
    minus: types.maybeNull(types.number),
    shots_on_goal: types.maybeNull(types.number),
    missed_shots: types.maybeNull(types.number),
    shots_on_post: types.maybeNull(types.number),
    blocked_shots: types.maybeNull(types.number),
    faceoff_won: types.maybeNull(types.optional(types.number, 0)),
    faceoff_lost: types.maybeNull(types.optional(types.number, 0)),
    faceoff_percentage: types.maybeNull(types.string),
    powerplay_points: types.maybeNull(types.number),
    boxplay_points: types.maybeNull(types.number),
    overtime_goals: types.maybeNull(types.number),
    game_winning_goals: types.maybeNull(types.number),
    time_on_ice_total: types.maybeNull(types.optional(types.number, 0)),
    time_on_ice_pp: types.maybeNull(types.optional(types.number, 0)),
    time_on_ice_bp: types.maybeNull(types.optional(types.number, 0)),
});

export default PlayerStats;
