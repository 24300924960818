import React, { useState, useEffect, memo } from 'react';
import Select from '../../components/Select';
import { seasons, positions, homeGames } from '../../constants/statsFilter';
import translations from '../../translations/de';
import Input from '../../components/Input';
import debounce from 'lodash.debounce';

const StatsFilter = ({
    showSeasonFilter = false,
    showNationalityFilter = false,
    showPositionFilter = false,
    showHomeGameFilter = false,
    returnQueries,
    activeSection,
    filters,
    handleFilterChange,
    children,
}) => {
    const [homeGame, setHomeGame] = useState(null);

    const handleNationalityFilter = debounce(
        (value) => {
            value !== '' &&
                handleFilterChange({
                    target: { name: 'nationality', value: value },
                });
        },
        [1000],
    );

    useEffect(() => {
        const queries = {
            season: showSeasonFilter ? filters?.season : undefined,
            nationality: showNationalityFilter ? filters?.nationality : undefined,
            position: showPositionFilter ? filters?.position : undefined,
            game_type: showHomeGameFilter ? homeGame : undefined,
        };
        returnQueries(queries);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        filters,
        homeGame,
        showSeasonFilter,
        showNationalityFilter,
        showPositionFilter,
        showHomeGameFilter,
    ]);

    let seasonDefault = seasons.find((season) => {
        if (season.value === filters.season) {
            return {
                value: season.value,
                label: season.label,
            };
        }
        return null;
    });

    let positionDefault = positions.find((position) => {
        if (position.value === filters.position) {
            return {
                value: position.value,
                label: position.label,
            };
        }
        return null;
    });

    return (
        <div className="filter-wrapper">
            {showSeasonFilter && (
                <div className="filter-box">
                    <Select
                        defaultValue={seasonDefault}
                        key={`${activeSection}-season`}
                        label={translations.season}
                        options={seasons.map((season) => ({
                            value: season.value,
                            label: season.label,
                        }))}
                        isClearable={true}
                        onChangeCallback={(e) =>
                            handleFilterChange({ target: { name: 'season', value: e?.value } })
                        }
                    />
                </div>
            )}
            {showNationalityFilter && (
                <div className="filter-box">
                    <Input
                        defaultValue={filters.nationality}
                        key={`${activeSection}-nationality`}
                        label={translations.nationality}
                        placeholder={translations.search}
                        onChange={(e) => handleNationalityFilter(e?.target.value)}
                    />
                </div>
            )}
            {showPositionFilter && (
                <div className="filter-box">
                    <Select
                        key={`${activeSection}-position`}
                        label={translations.position}
                        defaultValue={positionDefault}
                        isClearable={true}
                        options={positions.map((position) => ({
                            value: position.value,
                            label: position.label,
                        }))}
                        onChangeCallback={(e) =>
                            handleFilterChange({ target: { name: 'position', value: e?.value } })
                        }
                    />
                </div>
            )}
            {showHomeGameFilter && (
                <div className="filter-box">
                    <Select
                        defaultValue={positionDefault}
                        key={`${activeSection}-home_games`}
                        label={translations.home_games + ` / ` + translations.away_games}
                        options={homeGames.map((hg) => ({
                            value: hg.value,
                            label: hg.label,
                        }))}
                        isClearable={true}
                        onChangeCallback={(e) => setHomeGame(e?.value)}
                    />
                </div>
            )}

            {children}
        </div>
    );
};

export default memo(StatsFilter);
